import { text as t } from 'shared/text';

export const deviceTransactionsReportLayout = {    
    defaultSort:  {
        orderBy: 'createdOn',
        desc: true,
    },

    tableHeaders: {
        id: t.transactionId,
        phoneNumber: t.phoneNumber,
        transactionType: t.transactionType,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        net: t.netCash,
        createdOn: t.createdOn,
        receipt: t.receipt
    },

    sizes: {
        id: 'mid',
        phoneNumber: 'large',
        transactionType: 'mid',
        cashIn: 'mid',
        cashOut: 'mid',
        net: 'mid',
        createdOn: 'large',
        receipt: 'small'
    },

    layouts: {
        id: 'text-left',
        phoneNumber: 'text-left phone',
        transactionType: 'text-left',
        cashIn: 'blank text-right phone',
        cashOut: 'blank text-right phone',
        net: 'blank text-right',
        createdOn: 'text-left',
        receipt: 'phone'
    },

    formats: {
        phoneNumber: 'lastFour',
        transactionType: 'transactionType',
        cashIn: 'currency',
        cashOut: 'currency',
        net: 'currency',
        createdOn: 'date-standard'
    },

    hideSortArrows: {
        net: true
    },
    
    subtotals: {
        groupBy: 'all',
        fields: {
            id: 'header',
            cashIn: 'total',
            cashOut: 'total',
            net: {
                first: {column:'cashIn', type: 'total'},
                 calc: 'add',
                 second: {column:'cashOut', type: 'total'}
             },
        },
        layouts: {
            id: 'text-left',
            cashIn: 'text-right',
            cashOut: 'text-right',
            net: 'text-right'
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            net: 'currency'
        }
    },
    navigation: {
        receipt: {
            icon: 'receipt',
            onClick: true,
            validate: {
                type: 'prop',
                val: 's3Url'
            }
        }
    },
}